<template>
  <div>
    <h2>Населенные пункты</h2>
    <el-table stripe border :data="listCities.content" style="width: 100%">
      <el-table-column prop="cityName" label="Наименование"> </el-table-column>
      <el-table-column width="450" label="">
        <template slot-scope="raw">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                size="mini"
                style="float: right"
                @click="openEditModal(raw.row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
                size="mini"
                @click="deleteItem(raw.row)"
                style="float: right; margin-right: 10px"
                type="danger"
                >Удалить</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="listCities.size"
      :current-page.sync="listCities.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="listCities.totalElements"
    >
    </el-pagination>

    <el-dialog title="Классификатор отходов" :visible.sync="modalShow">
      <el-row :gutter="10">
        <el-col :span="20">
          <el-input v-model="cities.name"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button @click="save" type="primary">Сохранить</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "citiesSubClassifier",
  data() {
    return {
      searchPole: "",
      modalShow: false,
      cities: {
        cityId: null,
        areaId: null,
        districtId: null,
        typeId: null,
        cityName: "",
      },
      pageOptions: {
        page: 0,
        size: 20,
      },
    };
  },

  computed: {
    ...mapGetters({
      listCities: "GETLISTCITIES",
    }),
  },

  methods: {
    handleSizeChange(val) {
      this.getCitiesList({
        page: 0,
        size: val,
        areaId: this.$route.params.areaId,
        districtId: this.$route.params.districtId,
        typeId: this.$route.params.typeId,
      });
    },

    handleCurrentChange(val) {
      this.getCitiesList({
        page: val - 1,
        size: this.listCities.size,
        areaId: this.$route.params.areaId,
        districtId: this.$route.params.districtId,
        typeId: this.$route.params.typeId,
      });
    },

    openEditModal(item) {
      console.log(item);
      this.cities = item;
      this.modalShow = true;
    },

    deleteItem(item) {
      this.confirm(
        "Удалить район",
        "Вы уверены что хотите удалить район",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store.dispatch("deleteCity", item).then((response) => {
          if (response.data === "DELETED") {
            this.notification("Успешно", "Район удалена", "success");
            this.getCitiesList({
              areaId: this.$route.params.areaId,
              districtId: this.$route.params.districtId,
              typeId: this.$route.params.typeId,
              ...this.pageOptions,
            });
          }
        });
      });
    },

    viewItem(item) {
      this.$router.push("districts/" + item.districtId);
      console.log(item);
    },

    search() {},

    add() {
      this.cities = {
        cityId: null,
        areaId: null,
        districtId: null,
        typeId: null,
        cityName: "",
      };
      this.modalShow = true;
    },

    save() {
      this.cities.areaId = this.$route.params.areaId;
      this.cities.districtId = this.$route.params.districtId;
      this.cities.typeId = this.$route.params.typeId;
      if (this.cities.cityId === null) {
        this.$store.dispatch("createCity", this.district).then((response) => {
          if (response.data.cityId !== undefined) {
            this.notification("Успешно", "Район добавлена", "success");
            this.getCitiesList({
              areaId: this.$route.params.areaId,
              districtId: this.$route.params.districtId,
              typeId: this.$route.params.typeId,
              ...this.pageOptions,
            });
            this.modalShow = false;
          }
        });
      } else {
        this.$store.dispatch("updateCity", this.cities).then((response) => {
          if (response.data.cityId !== undefined) {
            this.notification("Успешно", "Район обновлена", "success");
            this.getCitiesList({
              areaId: this.$route.params.areaId,
              districtId: this.$route.params.districtId,
              typeId: this.$route.params.typeId,
              ...this.pageOptions,
            });
            this.modalShow = false;
          }
        });
      }
    },

    getCitiesList(data) {
      this.$store.dispatch("getListAllCitiesByAreaIdAndDistrictId", data);
    },
  },

  mounted() {
    this.getCitiesList({
      areaId: this.$route.params.areaId,
      districtId: this.$route.params.districtId,
      typeId: this.$route.params.typeId,
      ...this.pageOptions,
    });
  },
};
</script>

<style scoped></style>
