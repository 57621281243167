<template>
  <div>
    <cities-sub-classifier></cities-sub-classifier>
  </div>
</template>

<script>
import CitiesSubClassifier from "@/components/admin/subclassifiers/citiesSubClassifier";
export default {
  name: "citiesSubClassifierView",
  components: { CitiesSubClassifier },
};
</script>

<style scoped></style>
